<template>
  <div @click="showFilterMenu = false">
    <Loading :loading="loading" />

    <h4 class="title is-4">Form Inbox</h4>

    <div class="notification is-warning" v-if="user.subType == 'expired'">
      <b>Hey!</b> Your subscription has expired and the tax form inbox is a
      <b>Pro</b> feature.
      <router-link :to="{ name: 'Upgrade' }">Upgrade your account</router-link>
      to continue to access your tax form inbox.
    </div>

    <div class="box" v-else>
      <div style="margin-bottom: 10px" class="has-text-right">
        <button
          class="button is-small is-link"
          style="margin-left: 5px"
          @click="modal = 'export'"
        >
          <i class="fas fa-file-download" style="margin-right: 8px"></i> Export
        </button>
      </div>

      <div class="tabs">
        <ul>
          <li class="is-active"><a>All Forms</a></li>
          <!-- <li><a>W-9</a></li>
          <li><a>W-8BEN</a></li> -->
        </ul>
      </div>

      <table class="table is-fullwidth" v-if="forms.length">
        <thead>
          <tr>
            <th><a>Form No.</a></th>
            <th><a>Name</a></th>
            <th><a>Email</a></th>
            <th>
              <a>Received <i class="fas fa-sort-down"></i></a>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="form in forms"
            :key="form.id"
            @click="
              $router.push({
                name: 'Form',
                params: { id: form.id },
              })
            "
          >
            <td>{{ form.formNumberPretty }}</td>
            <td>{{ form.name }}</td>
            <td>{{ form.senderEmail }}</td>
            <td>{{ dayjs(form.createdAt).format('MM/DD/YYYY') }}</td>
          </tr>
        </tbody>
      </table>
      <span v-else> No forms received. </span>
      <div class="buttons" v-if="hasMore">
        <button
          class="button is-small"
          @click="showMore"
          :class="{ 'is-loading': loadingMore }"
        >
          Show More
        </button>
      </div>
    </div>

    <div class="modal is-active" v-if="modal == 'export'">
      <div class="modal-background" @click="modal = ''"></div>
      <div class="modal-content">
        <div class="box">
          <h5 class="title is-5">Export</h5>
          <div class="content">
            <!-- <p>xxx Filters will be applied</p> -->
            <h6>Forms</h6>
            <div class="control">
              <label class="radio">
                <input
                  type="radio"
                  name="form-no"
                  value="w9"
                  v-model="exportOptions.formNumber"
                />
                W-9
              </label>
              <label class="radio">
                <input
                  type="radio"
                  name="form-no"
                  value="w8ben"
                  v-model="exportOptions.formNumber"
                />
                W-8BEN
              </label>
            </div>
            <br />
            <h6>Country options</h6>
            <div class="field">
              <div class="control">
                <label class="radio" :disabled="exportOptions.formNumber == 'w9'">
                  <input
                    type="radio"
                    name="country-code"
                    value="iso"
                    :disabled="exportOptions.formNumber == 'w9'"
                    v-model="exportOptions.countryCode"
                  />
                  ISO country codes
                </label>
                <label class="radio" :disabled="exportOptions.formNumber == 'w9'">
                  <input
                    type="radio"
                    name="country-code"
                    value="irs"
                    :disabled="exportOptions.formNumber == 'w9'"
                    v-model="exportOptions.countryCode"
                  />
                  IRS country codes
                </label>
              </div>
            </div>
            <div class="field">
              <div class="control">
                <label class="checkbox" :disabled="exportOptions.formNumber == 'w9'">
                  <input
                    type="checkbox"
                    v-model="exportOptions.countryName"
                    :disabled="exportOptions.formNumber == 'w9'"
                  />
                  Include full country names
                </label>
              </div>
            </div>
          </div>
          <div class="field">
            <div class="buttons">
              <button class="button is-link" @click="exportCsv()">Export CSV</button>
              <button class="button" @click="modal = ''">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <FlashMessage :flash="flash" />
  </div>
</template>

<style scoped lang="scss">
div.is-grey {
  box-shadow: none;
  background-color: #f5f5f5;
}

table.table tbody tr:hover {
  cursor: pointer;
  background-color: #fafafa;
}

table.table thead th a {
  color: #363636;
}

.filter-button {
  position: relative;
  text-decoration: none !important;
}

.filter-menu {
  background: #fff;
  border-radius: 6px;
  padding: 18px;
  color: hsl(0, 0%, 4%);
  box-shadow: 0 8px 8px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  position: absolute;
  top: 30px;
  left: 0px;
  width: 300px;
}
</style>

<script>
import dayjs from 'dayjs'
import { api } from '@/modules/util'

export default {
  name: 'RequestForm',
  props: ['user'],
  data() {
    return {
      dayjs,
      // formNumber: select(),
      // receiverEmail: field(),
      flash: [],
      processing: false,
      forms: [],
      loading: true,
      loadingMore: true,
      hasMore: true,
      offset: 0,
      modal: '',
      filterKey: 0,
      filters: {
        formNumber: '',
        between: [null, null],
        email: '',
        name: '',
        businessName: '',
      },
      exportOptions: {
        formNumber: 'w9',
        countryCode: 'iso',
        countryName: false,
      },
      showFilterMenu: false,
    }
  },
  methods: {
    // toggleFilterMenu() {
    //   this.showFilterMenu = !this.showFilterMenu
    // },
    // filter() {
    //   return {
    //     key: this.filterKey++,
    //     column: 'formNumber',
    //     operation: 'eq',
    //     value: 'w8ben'
    //   }
    // },
    // valuePretty(v) {
    //   return (v == 'w9') ? 'W-9'
    //        : (v == 'w8ben') ? 'W-8BEN'
    //        : (v instanceof Date) ? this.dayjs(v).format('MMM D, YYYY')
    //        : ''
    // },
    // columnPretty(s) {
    //   return (s == 'formNumber') ? 'Form No.'
    //        : (s == 'name') ? 'Name'
    //        : (s == 'entity') ? 'Entity'
    //        : (s == 'senderEmail') ? 'Email'
    //        : ''
    // },
    // operationPretty(s) {
    //   return (s == 'eq') ? 'is'
    //        : (s == 'like') ? 'includes'
    //        : (s == 'between') ? 'between'
    //        : ''
    // },

    async init() {
      try {
        this.loading = true
        this.loadingMore = true
        this.hasMore = true
        const res = await api.get('/forms/received')
        if (res.data.forms.length < 10) this.hasMore = false
        this.forms = res.data.forms
        this.loading = false
        this.loadingMore = false
      } catch (err) {
        this.loading = false
        this.loadingMore = false
      }
    },
    async showMore() {
      if (this.loadingMore || !this.hasMore) return
      try {
        this.loadingMore = true
        this.offset++
        const res = await api.get(`/forms/received?offset=${this.offset}`)
        if (res.data.forms.length < 10) this.hasMore = false
        if (res.data.forms.length) this.forms.push(...res.data.forms)
        this.loadingMore = false
      } catch (err) {
        this.flash.push({ msg: err, type: 'error' })
        this.loadingMore = false
      }
    },
    async exportCsv() {
      try {
        const res = await api.post(
          `/forms/${this.exportOptions.formNumber}/export`,
          {
            exportOptions: this.exportOptions,
          },
          {
            responseType: 'blob',
          }
        )

        // file download boilerplate
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'export.csv')
        document.body.appendChild(link)
        link.click()
        link.remove()
      } catch (err) {
        this.flash.push({ msg: err, type: 'error' })
      }
    },
  },
  mounted() {
    this.init()
  },
}
</script>
